






















import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapState, mapMutations } from 'vuex'
import { MapDisplay } from './components/MapDisplay.vue';
import { TableDisplay } from './components/TableDisplay.vue';
import axios from 'axios';

@Component({
  components: {
    MapDisplay,
    TableDisplay,
  },
  computed: {
    ...mapGetters([
        'states',
        'voteData',
    ]),
    ...mapState([
      'states',
    ]),
    ...mapMutations([
      'setStates',
      'setVoteData',
    ])
  }
})
export default class App extends Vue {
  year = "2016";
  availableYears = ["2016"];
  pkey = "evpower_simple";
  
  mounted() {
    axios.get('/election/all').then(
      response => {
        this.$store.commit('setVoteData', { voteData: response.data });
        const years: string[] = [];
        for (const y in response.data) {
          years.push(y);
          response.data[y].totals.evpowerMax = response.data[y].totals.evpower_simple_max;
          response.data[y].totals.evpowerMin = response.data[y].totals.evpower_simple_max;
        }
        years.sort().reverse();
        this.availableYears=years;
      }
    )
  }
}
