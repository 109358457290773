





































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
} from 'vuex-class'
//import statelist from "@/statelist";
// TODO: move to common place
import { StateVote, VoteData } from "./MapDisplay.vue";
import statelist from "@/statelist";

@Component
export class TableDisplay extends Vue {
  @Prop() private voteData!: VoteData;
  @Prop() private year!: string;
  @Prop() private pkey!: string;

  get currentStates() {
      const result = Object.values(this?.voteData?.[this.year]?.states || {});
      result.sort( (a, b) => a[this.pkey] - b[this.pkey]).reverse();
      return result;
  }

  stateName(id: string): string {
    return statelist[id];
  }

  stateClass(state): string {
    if(state.ev_dem > state.ev_rep) {
      return 'row_dem';
    } else {
      return 'row_rep';
    }
  }

  popBarStyle(pv: number): object {
    const width = pv / 4000000 * 5;
    return { width: width.toFixed(2) + 'em' };
  }

  popBarDemStyle(state: StateVote): object {
    return this.popBarStyle(state.pv_dem);
  }

  popBarRepStyle(state: StateVote): object {
    return this.popBarStyle(state.pv_rep);
  }
}

export default TableDisplay;

