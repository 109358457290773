


































import { Component, Prop, Vue } from 'vue-property-decorator';
import {
} from 'vuex-class'
import statelist from "@/statelist";

import { SvgMap } from "vue-svg-map";
import USA from "@svg-maps/usa";


interface MapLocation {
  id: string;
  name: string;
}

interface MapAttributes {
  id: {
    value: string;
  };
  name: {
    value: string;
  };
}

interface  MapTarget {
  attributes: MapAttributes;
}

export interface StateVote {
  state: string;
  ev_dem: number;
  ev_rep: number;
  pv_dem: number;
  pv_rep: number;
  pv_others: number;
  pv_total: number;
  evpower_simple: number;
}

export interface VoteData { [key: string]: { [key: string]: StateVote } }

@Component({
    components: {
        SvgMap,
    },
})
export class MapDisplay extends Vue {
  @Prop() private msg!: string;
  @Prop() private voteData!: VoteData;
  @Prop() private year!: string;
  @Prop() private pkey!: string;

  USA = USA;
  selectedState: string | null = "";
  pointedLocation: string | null = "";
  tooltipStyle: object = { display: "none" };

  private getState(location: string): StateVote | object {
    return this.voteData?.[this.year]?.states[location.toUpperCase()] || {};
  }

  private get pointedState(): StateVote | object {
    const id = this.pointedLocation;
    if (!id) {
      return {};
    }
    return this.getState(id);
  }

  private getStateString(stateId: string | null): string {
    if (stateId) {
      return statelist[stateId.toUpperCase()];
    } else {
      return "<None>";
    }
  }

  private get stateString(): string {
    return this.getStateString(this.selectedState);
  }

  private getLocationClass(location: MapLocation) {
    if (location?.id) {
      const stateRaw = this.getState(location.id);
      if (stateRaw) {
        const pkey = this.pkey;
        const state = stateRaw as StateVote;
        const evpower = (state as StateVote)[pkey];
        const party = (state.ev_dem > state.ev_rep) ? "ev-democrat" : "ev-republican";
        const totals: object = this.voteData[this.year]?.totals;
        if (totals) {
          const min = totals[pkey + '_min'];
          const max = totals[pkey + '_max'];
          const scaled = ((evpower - min) / (max - min)) * 0.9 + 0.1;
          // min -> 0.1, max -> 1 
          const logpower = Math.log10(scaled) 
          let power = Math.round((logpower + 1) * 10);
          power = Math.min(Math.max(power, 1), 10);
          return `${party} ev-weight-${power}`;
        }
      }
    }
    return "";
  }

  private getLocationId(eventtarget: EventTarget | null) {
    const target: MapTarget = eventtarget as unknown as MapTarget;
    return target.attributes.id.value;
  }

  private getLocationName(eventtarget: EventTarget | null) {
    const target: MapTarget = eventtarget as unknown as MapTarget;
    const id = target?.attributes?.id?.value;
    return id ? statelist[id.toUpperCase()] : "";
  }

  private get electoralVotes() {
    if (this.selectedState) {
      return this.voteData[this.year].states[this.selectedState.toUpperCase()].ev_dem;
    } else {
      return "";
    }
  }

  private onClick() {
    return;
  }

	private	pointLocation(event: Event) {
		this.pointedLocation = this.getLocationId(event.target)
  }
    
  unpointLocation() {
    this.pointedLocation = null
    this.tooltipStyle = { display: 'none' }
  }

  moveOnLocation(event: MouseEvent) {
    if(event?.target?.['id']) {
      this.tooltipStyle = {
        display: 'block',
        top: `${event.clientY + 10}px`,
        left: `${event.clientX - 1}px`,
      }
    }
    event.stopPropagation();
  }

  baseMove() {
    this.unpointLocation();
  }
}

export default MapDisplay;

